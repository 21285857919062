<ion-content>
    <div class="p-4">
        <div class="d-flex justify-content-between align-items-center">
            <h1 *ngIf="company">{{ "Company infos" | translate }}</h1>
            <h1 *ngIf="!company">{{ "Become pro" | translate }}</h1>
        </div>
        <!-- Create pro form with vatRegistrationNumber field, registrationNumber and company name fields -->
        <form (submit)="submitForm()" [formGroup]="form">
            <!-- shopName -->
            <div class="form-group">
                <label for="shopName" class="required">{{ "Shop Name" | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="shopName"
                    formControlName="shopName"
                    [class.is-invalid]="form.get('shopName').invalid && form.get('shopName').touched"
                />
            </div>
            <!-- Name field -->
            <div class="form-group">
                <label for="name" class="required">{{ "Company Name" | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    formControlName="name"
                    [class.is-invalid]="form.get('name').invalid && form.get('name').touched"
                />
            </div>
            <!-- VAT number field -->
            <div class="form-group">
                <label for="vatNumber" class="required">{{ "VAT Number" | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="vatNumber"
                    formControlName="vatNumber"
                    [class.is-invalid]="form.get('vatNumber').invalid && form.get('vatNumber').touched"
                />
            </div>
            <!-- Registration number field -->
            <div class="form-group">
                <label for="registrationNumber" class="required">{{ "Registration Number" | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="registrationNumber"
                    formControlName="registrationNumber"
                    [class.is-invalid]="form.get('registrationNumber').invalid && form.get('registrationNumber').touched"
                />
            </div>
            <!-- Activity field -->
            <div class="form-group">
                <label for="activity" class="required">{{ "Activity" | translate }}</label>
                <select
                    class="form-select"
                    id="activity"
                    formControlName="activity"
                    [class.is-invalid]="form.get('activity').invalid && form.get('activity').touched"
                >
                    <option value="" disabled selected>{{ "Select an activity" | translate }}</option>
                    <option *ngFor="let activity of activities" [value]="activity.value">{{ activity.label | translate }}</option>
                </select>
            </div>
            <ng-container *ngIf="form.get('activity').value === 'real_estate' && form.get('authorizationNumber') && form.get('proNumber')">
                <!-- Pro number field -->
                <div class="form-group">
                    <label for="proNumber" class="required">{{ "company_activity.real_estate.pro_number" | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="proNumber"
                        formControlName="proNumber"
                        required
                        [class.is-invalid]="form.get('proNumber')?.invalid && form.get('proNumber')?.touched"
                    />
                </div>
                <!-- Authorization number field -->
                <div class="form-group">
                    <label for="authorizationNumberRealEstate" class="required">{{
                        "company_activity.real_estate.authorization_number" | translate
                    }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="authorizationNumberRealEstate"
                        formControlName="authorizationNumber"
                        required
                        [class.is-invalid]="form.get('authorizationNumber')?.invalid && form.get('authorizationNumber')?.touched"
                    />
                </div>
            </ng-container>
            <ng-container *ngIf="form.get('activity').value === 'car_dealership' && form.get('authorizationNumber')">
                <!-- Authorization number field -->
                <div class="form-group">
                    <label for="authorizationNumberCarDealership" class="required">{{
                        "company_activity.car_dealership.authorization_number" | translate
                    }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="authorizationNumberCarDealership"
                        formControlName="authorizationNumber"
                        required
                        [class.is-invalid]="form.get('authorizationNumber')?.invalid && form.get('authorizationNumber')?.touched"
                    />
                </div>
            </ng-container>
            <div class="form-group d-flex justify-content-between align-items-center">
                <button class="btn btn-outline-primary" type="button" (click)="cancel()">{{ "Cancel" | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="loading">{{ "Save" | translate }}</button>
            </div>
        </form>
    </div>
</ion-content>
