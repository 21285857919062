import { _ } from '@ngx-translate/core';

export class CompanyUtils {
    static activities = [
        { value: _('real_estate'), label: _('Real estate') },
        { value: _('car_dealership'), label: _('Car dealership') },
        { value: _('fashion'), label: _('Fashion') },
        { value: _('other'), label: _('other') },
    ];
    static positions = [
        { id: 'director', name: _('Director') },
        { id: 'manager', name: _('Manager') },
        { id: 'secretary', name: _('Secretary') },
        { id: 'accountant', name: _('Accountant') },
        { id: 'marketing', name: _('Marketing') },
        { id: 'seller', name: _('Seller') },
        { id: 'hr', name: _('HR') },
        { id: 'it', name: _('IT') },
        { id: 'other', name: _('other') },
    ];
}
