import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { Company, CompanyActivity } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { UserService } from '../../services/user.service';
import { CompanyUtils } from '../../utils/company.utils';

@Component({
    selector: 'app-company-modal',
    templateUrl: './company-modal.component.html',
    styleUrls: ['./company-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, ReactiveFormsModule],
})
export class CompanyModalComponent implements OnInit {
    form: FormGroup<any>;
    @Input() company: Company;
    activities = CompanyUtils.activities;
    loading = false;

    constructor(
        private readonly companyService: CompanyService,
        private readonly modalCtrl: ModalController,
        private readonly userService: UserService,
    ) {}

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl(this.company?.name, Validators.required),
            registrationNumber: new FormControl(this.company?.registrationNumber, Validators.required),
            vatNumber: new FormControl(this.company?.vatNumber, Validators.required),
            activity: new FormControl(this.company?.activity, Validators.required),
            shopName: new FormControl(this.company?.shopName, Validators.required),
        });

        this.form.get('activity').valueChanges.subscribe({
            next: (activity: CompanyActivity) => {
                this.changeActivity(activity);
            },
        });

        this.changeActivity(this.form.get('activity').value);
    }

    private changeActivity(activity: CompanyActivity) {
        switch (activity) {
            case 'car_dealership':
                this.form.addControl('authorizationNumber', new FormControl(this.company?.authorizationNumber, Validators.required));
                break;
            case 'real_estate':
                this.form.addControl('authorizationNumber', new FormControl(this.company?.authorizationNumber, Validators.required));
                this.form.addControl('proNumber', new FormControl(this.company?.proNumber, Validators.required));
                break;
            default:
                if (this.form.get('authorizationNumber')) {
                    this.form.removeControl('authorizationNumber');
                }
                if (this.form.get('proNumber')) {
                    this.form.removeControl('proNumber');
                }
                break;
        }
    }

    async submitForm() {
        if (this.form.invalid) {
            this.loading = false;
            this.form.markAllAsTouched();
            return;
        }
        this.loading = true;
        await LoadingUtils.show();
        if (this.company) {
            // Update company data
            this.companyService.update(this.company.id, this.form.value).subscribe({
                next: () => {
                    LoadingUtils.hide();
                    this.modalCtrl.dismiss();
                },
                error: () => {
                    LoadingUtils.hide();
                },
            });
            return;
        }

        // Create company
        const data = { ...this.form.value };
        data.companyName = data.name;
        delete data.name;
        this.userService.createProAccount(data).subscribe({
            next: () => {
                LoadingUtils.hide();
                this.modalCtrl.dismiss();
            },
            error: () => {
                LoadingUtils.hide();
            },
        });
    }

    cancel() {
        this.modalCtrl.dismiss();
    }
}
