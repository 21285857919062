import { _ } from '@ngx-translate/core';
import { Company, CompanyActivity } from '../models/company';
import { User } from '../models/user';

export interface Tab {
    icon: string;
    name: string;
    path: string;
    type: 'all' | 'personal' | 'pro' | 'team';
    activities?: CompanyActivity[];
}

export class ProfileTabs {
    static getTabs(user: User = null, company: Company = null): Tab[] {
        const tabs: Tab[] = [
            {
                name: _('Profile'),
                path: '/profile/home',
                type: 'all',
                icon: 'person-fill',
            },
            {
                name: _('My personal data'),
                path: '/profile/personal-info',
                type: 'all',
                icon: 'person-lines-fill',
            },
            {
                name: _('Account security'),
                path: '/profile/account-security',
                type: 'all',
                icon: 'key',
            },
            {
                name: _('My addresses'),
                path: '/profile/addresses',
                type: 'all',
                icon: 'geo',
            },
            {
                name: _('Company'),
                path: '/profile/company',
                type: 'pro',
                icon: 'building',
            },
            {
                name: _('Size guide'),
                path: '/profile/size-guide',
                type: 'all',
                icon: 'rulers',
                activities: ['fashion'],
            },
            {
                name: _('Team'),
                path: '/profile/team',
                type: 'pro',
                icon: 'people',
            },
            {
                name: _('Wallet'),
                path: '/profile/wallet',
                type: 'all',
                icon: 'wallet',
            },
            {
                name: _('Offers'),
                path: '/profile/offers',
                type: 'all',
                icon: 'tags',
            },
            {
                name: _('Sales'),
                path: '/profile/sales',
                type: 'pro',
                icon: 'list-check',
            },
            {
                name: _('Purchases'),
                path: '/profile/purchases',
                type: 'all',
                icon: 'cart-check',
            },
            // { name: _('Ads', path: '/profile/ads', type: 'pro' },
        ];

        return tabs.filter((tab) => {
            return (tab.type === 'all' || tab.type === user?.type) && (!tab.activities || tab.activities.includes(company?.activity));
        });
    }
}
